import { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomerAccessTokenCreateInput } from '@ts/shopify-storefront-api';
import { EMAIL_VALIDATION_REG_EX, MIN_VALUE, NEXT_APP_ENV } from '@constants';
import { Alert, Button, Heading, Input } from '@components';
import { trackLogin } from '@services/analytics/trackers';
import useLogin from '@services/shopify/operations/login';
import styles from './LoginForm.module.scss';
import RecoveryForm from './RecoveryForm';

const LoginForm = () => {
	const router = useRouter();
	const [infoAlert, setInfoAlert] = useState(router.query.info);
	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<CustomerAccessTokenCreateInput>({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const login = useLogin();

	const [recoveryMode, setRecoveryMode] = useState(false);
	const [submitErrors, setSubmitErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const checkoutQueryParam = router.query.checkout_url;
	const checkoutUrl =
		checkoutQueryParam &&
		!checkoutQueryParam.includes('https://paireyewear.com/rewards') &&
		`https://shop.paireyewear.com${checkoutQueryParam}`;

	const onSubmit: SubmitHandler<CustomerAccessTokenCreateInput> = async data => {
		try {
			setLoading(true);
			await login(data);
			setInfoAlert(null);
			setSubmitErrors([]);
			if (isProd) trackLogin();
			// Handles Yotpo Rewards page issue
			if (checkoutQueryParam && checkoutQueryParam.includes('https://paireyewear.com/rewards')) {
				router.push('/rewards');
				reset();
			}
			const returnUrl = (router.query.returnUrl as string) || '/';
			router.push(returnUrl);
			reset();
		} catch (error) {
			setLoading(false);
			reset();
			setInfoAlert(null);
			return setSubmitErrors(error.errors);
		}
	};

	// Handle redirect back to checkout for users who login at checkout
	const onSubmitCheckoutRedirect: SubmitHandler<CustomerAccessTokenCreateInput> = async data => {
		try {
			setLoading(true);
			const token = await login(data);
			setInfoAlert(null);
			setSubmitErrors([]);
			if (isProd) trackLogin();
			const res = await fetch('/api/multipass', {
				method: 'POST',
				body: JSON.stringify({
					email: data.email,
					accessToken: token,
					return_to: checkoutUrl,
				}),
			});
			const { url } = await res.json();
			router.push(url.replace('pair-eyewear.myshopify.com', 'shop.paireyewear.com'));
			reset();
		} catch (error) {
			setLoading(false);
			reset();
			setInfoAlert(null);
			return setSubmitErrors(error.errors);
		}
	};

	const currentEmailInput = watch('email');
	const currentPasswordInput = watch('password');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);

	return recoveryMode ? (
		<RecoveryForm cancelRecovery={() => setRecoveryMode(false)} />
	) : (
		<form
			className={styles.container}
			onSubmit={checkoutUrl ? handleSubmit(onSubmitCheckoutRedirect) : handleSubmit(onSubmit)}
		>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				Log In
			</Heading>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			{infoAlert && <Alert info={!!infoAlert} />}
			<Input
				{...register('email', {
					required: 'Please fill out this field.',
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: 'Invalid email address.',
					},
				})}
				errorMessage={errors?.email?.message}
				id='CustomerEmail'
				name='email'
				placeholder='Email'
				type='email'
				withIcon
			/>
			<Input
				{...register('password', {
					required: 'Please fill out this field.',
					minLength: {
						value: MIN_VALUE,
						message: `Please lengthen this text to 5 characters or more (you are currently using ${
							currentPasswordInput.length
						} characters).`,
					},
				})}
				errorMessage={errors?.password?.message}
				id='LoginCustomerPassword'
				name='password'
				placeholder='Password'
				type='password'
				withIcon
			/>
			<Button linkStyle label='Forgot your Password?' onClick={() => setRecoveryMode(true)} />

			<div className={styles.buttons}>
				<Button href='/account/register' fullWidth label='Create Account' color='white' />
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label='Sign In'
					disabled={!(isValidEmail && currentPasswordInput.length >= MIN_VALUE)}
				/>
			</div>
		</form>
	);
};
export default LoginForm;
